import React, { Fragment } from "react"
import Layout from "../components/layout"

import "./about.scss"
export default () => (
  <Fragment>
    <Layout>
      <div className="container">
        <div className="container about-text text-center text-sm-left mt-2 mt-sm-5">
          <h1 className="">About</h1>
        </div>

        <div className="row ">
          <div className="col-sm mt-2 mt-sm-0">
            <img
              src="https://placeimg.com/240/340/people"
              class="rounded mx-auto d-block image__back"
              alt="Architect building"
            />
          </div>
          <div className="col-sm mt-3 mt-sm-3 ">
            <div class="d-flex flex-column justify-content-between">
              <div class="p-2">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent ullamcorper magna quis lectus tempus, eu euismod velit
                pulvinar. Donec et sem elit. Mauris in libero luctus, tincidunt
                ligula quis, finibus magna. Maecenas sed condimentum leo, at
                tempor augue. Nulla non suscipit tortor, sed aliquet justo.
                Integer eros leo, sagittis malesuada dolor eget, vehicula
                commodo mauris. Fusce rhoncus dui sem, quis fermentum tellus
                tincidunt porttitor. Praesent aliquet sagittis sollicitudin.
                Donec lacinia iaculis nisl nec placerat.
              </div>
              <div class="p-2 mt-0 mt-lg-5">
                <a
                  href="/documents/resume.pdf"
                  target="blank"
                  download="resume.pdf"
                >
                  <svg height="30" viewBox="0 0 512 512" width="30">
                    <path d="m384 496h-336v-480h272l64 64z" fill="#ffffff" />
                    <path d="m320 16v64h64" fill="#ffffff" />
                    <g fill="#1abc9c">
                      <path d="m88 112h256v32h-256z" />
                      <path d="m88 176h256v32h-256z" />
                      <path d="m88 240h256v32h-256z" />
                    </g>
                    <circle cx="384" cy="384" fill="#e0e0e2" r="88" />
                    <path d="m336 384h24v-48h48v48h24l-48 48z" fill="#1abc9c" />
                    <path d="m112 464h-40a8 8 0 0 0 0 16h40a8 8 0 0 0 0-16z" />
                    <path d="m144 464h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z" />
                    <path d="m88 152h256a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8h-256a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8zm8-32h240v16h-240z" />
                    <path d="m88 216h256a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8h-256a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8zm8-32h240v16h-240z" />
                    <path d="m88 280h256a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8h-256a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8zm8-32h240v16h-240z" />
                    <path d="m240 296h-152a8 8 0 0 0 0 16h152a8 8 0 0 0 0-16z" />
                    <path d="m192 320h-104a8 8 0 0 0 0 16h104a8 8 0 0 0 0-16z" />
                    <path d="m104 360a8 8 0 0 0 0-16h-16a8 8 0 0 0 0 16z" />
                    <path d="m136 360a8 8 0 0 0 0-16h-8a8 8 0 0 0 0 16z" />
                    <path d="m480 384a96.127 96.127 0 0 0 -88-95.664v-208.336a8 8 0 0 0 -2.343-5.657l-64-64a8 8 0 0 0 -5.657-2.343h-272a8 8 0 0 0 -8 8v480a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8v-16.336a96.127 96.127 0 0 0 88-95.664zm-152-348.687 36.687 36.687h-36.687zm-272 452.687v-464h256v56a8 8 0 0 0 8 8h56v200.336a96 96 0 0 0 0 191.328v8.336zm328-24a80 80 0 1 1 80-80 80.091 80.091 0 0 1 -80 80z" />
                    <path d="m432 376h-16v-40a8 8 0 0 0 -8-8h-48a8 8 0 0 0 -8 8v40h-16a8 8 0 0 0 -5.657 13.657l48 48a8 8 0 0 0 11.314 0l48-48a8 8 0 0 0 -5.657-13.657zm-48 44.687-28.687-28.687h4.687a8 8 0 0 0 8-8v-40h32v40a8 8 0 0 0 8 8h4.687z" />
                  </svg>{" "}
                  My Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </Fragment>
)

// https://placeimg.com/480/680/arch

// <img
// src="https://placeimg.com/240/340/people"
// class=" "
// alt="Architect building"
// />
